@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url(./assets/fonts/Inter/Inter-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  src: url(./assets/fonts/Inter/Inter-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 200;
  src: url(./assets/fonts/Inter/Inter-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: url(./assets/fonts/Inter/Inter-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url(./assets/fonts/Inter/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 100;
  src: url(./assets/fonts/Inter/Inter-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 100;
  src: url(./assets/fonts/Nunito/Nunito-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 900;
  src: url(./assets/fonts/Nunito/Nunito-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 700;
  src: url(./assets/fonts/Nunito/Nunito-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 800;
  src: url(./assets/fonts/Nunito/Nunito-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 200;
  src: url(./assets/fonts/Nunito/Nunito-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src: url(./assets/fonts/Nunito/Nunito-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  src: url(./assets/fonts/Nunito/Nunito-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 500;
  src: url(./assets/fonts/Nunito/Nunito-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 500;
  src: url(./assets/fonts/Nunito/Nunito-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 400;
  src: url(./assets/fonts/Nunito/Nunito-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 600;
  src: url(./assets/fonts/Nunito/Nunito-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  src: url(./assets/fonts/Nunito/Nunito-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 900;
  font-style: italic;
  src: url(./assets/fonts/Nunito/Nunito-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 700;
  font-style: italic;
  src: url(./assets/fonts/Nunito/Nunito-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 800;
  font-style: italic;
  src: url(./assets/fonts/Nunito/Nunito-ExtraBoldItalic.ttf) format('truetype');
}




body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.soft-shadow {
  box-shadow: 2px 4px 36px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 2px 4px 36px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 2px 4px 36px 0px rgba(0,0,0,0.1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.student-video-player video {
  border-radius: 30px;
}

.show-modal {
  visibility: visible !important
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fadeOut {
  opacity: 0;
  transition: all 250ms linear 2s;
}

#speak-on-it-modal {
  padding: 14px;
  width: 800px;
  background: white;
  border-radius: 30px;
  height: 397.4px;
  /* z-index: 999999999; */
  flex-direction: column;
  font-family: 'Nunito'
}

.ant-steps-item-process .ant-steps-item-icon {
  background: #652CE6 !important;
  border: 0;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #e1daf0 !important;
  border:none !important;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: #652CE6 !important
}

:root {
  --dot-size: 1rem;
  --animation-duration: 2s;
}

.dot {
  width: var(--dot-size);
  height: var(--dot-size);
  background-color: red;
  border-radius: 50%;
}

/* Define animation keyframes */
@keyframes blink {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

/* Minimal example */
.dot--basic {
  animation: blink 2s infinite;
}

/* Run animation once */
.dot--once {
  animation: blink 2s 1;
  /* animation-iteration-count: 1; */
}

/* Wait 4s before running the animation */
.dot--delayed {
  animation: blink 2s infinite 4s;
  /* animation-delay: 4s; */
}

/* Use frames with "from" and "to" */
@keyframes choppyBlink {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.dot--choppy {
  animation: choppyBlink 2s infinite;
  /* animation-name: choppyBlink; */
}

/* Animate multiple properties (transform and color) */
@keyframes pulse {

  0%,
  100% {
    transform: scale(0) translateY(-75%);
    background-color: blue;
  }

  33% {
    background-color: orange;
  }

  50% {
    transform: scale(1.125) translateY(0);
    background-color: purple;
  }
}

.dot--pulse {
  animation: pulse 8s infinite;
}

/* Disable animation if user prefers reduced motion */
@media (prefers-reduced-motion: reduce) {
  .dot {
    animation: none;
  }
}

.introjs-tooltip {
  min-width: 400px !important;
}
/* 
.Toastify__toast--warning {
  background: #bfabfc !important;
}

.Toastify__toast--error {
  background: #A78BFA !important;
} */

.allow_permissions_modal .ant-btn-primary {
  background: #9C1FFF
}

.allow_permissions_modal .ant-btn-primary:hover {
  background: #9C1FFF
}

.dot {
  width: var(--dot-size);
  height: var(--dot-size);
  background-color: #A68BFA;
  border-radius: 50%;
}

#start_talking_btn svg {
  margin-top: 3px;
}

.status-tag {
  padding: 8px 20px;
  border-width: 2px;
  font-weight: 500;
  font-size: 16px;
  cursor: 'pointer';
  text-align: center;
}

.ant-progress-outer {
  display: None !important;
}